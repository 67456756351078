@import './shared/styles/_vuetify-overrides.scss';






























































.cassie-tabs--container {
  border-radius: var(--cassie-tabs-container-border-radius);
  box-shadow: var(--cassie-tabs-container-box-shadow);
  .cassie-tab {
    max-width: 25% !important;
    font-size: var(--cassie-tab-font-size);
    text-transform: var(--cassie-tab-text-transform);
    border-right: var(--cassie-tab-border-right);
    &:nth-last-child(1) {
      border-right: none;
    }
  }

  .cassie-active-tab.cassie-active-tab {
    color: var(--cassie-tab-active-text-color);
    background-color: var(--cassie-tab-active-background-color);
    border-radius: var(--cassie-tab-active-border-radius);
  }
}
